import "./App.css";
import LogIn from "./views/login/LogIn";
import { createClient, WagmiConfig } from "wagmi";
import { getDefaultProvider } from "ethers";
import Home from "./views/home/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import { Fragment } from "react";
import Error404 from "./components/pageerror/Error404";
function App() {
  const client = createClient({
    autoConnect: true,

    provider: getDefaultProvider(),
  });
  return (
    <WagmiConfig client={client}>
      <BrowserRouter>
        <Fragment>
          <Routes>
            <Route path="/" element={<LogIn />} />
            <Route exact path="/Home" element={<Home />} />
          </Routes>
        </Fragment>
      </BrowserRouter>
    </WagmiConfig>
  );
}

export default App;
