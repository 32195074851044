import React, { useState } from "react";
import "./home.css";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import axios from "axios";

function Home() {
  const [open, setopen] = useState(false);
  const [data, setdata] = useState("5 minutes");
  const [privateKey, setprivateKey] = useState("");

  const update = async () => {
    const save_data = {
      timeInterval: data,
    };

    await axios
      .post(`${process.env.REACT_APP_AXIOS_URL}/api/v1/reward`, save_data)
      .then((res) => {
        console.log(res.data);
        setdata("5 minutes");
      });
  };

  const updatePrivateKey = async () => {
    const save_data = {
      walletAddress: window.localStorage.getItem("walletId"),
      privateKey: privateKey,
    };

    await axios
      .post(`${process.env.REACT_APP_AXIOS_URL}/api/v1/admin`, save_data)
      .then((res) => {
        console.log(res.data);
        setprivateKey("");
      });
  };

  return (
    <div className="home">
      <div className="card">
        <div className="container">
          {/* textfield time dropdrown 1 day one week five minutes */}
          <div className="from-input">
            <label htmlFor="" className="title">
              Private Key
            </label>
            <input
              type="text"
              onChange={(e) => setprivateKey(e.target.value)}
              value={privateKey}
            />
          </div>
          <div className="from-input">
            <label htmlFor="" className="title">
              Time
            </label>
            {/* <input type="text" /> */}
          </div>
          <div className="from-drop">
            <button onClick={() => setopen(!open)}>
              {data} {open ? <IoMdArrowDropdown /> : <IoMdArrowDropup />}
              <ul style={{ display: !open ? "none" : null }}>
                <li onClick={() => setdata("5 minutes")}>5 minutes</li>
                <li onClick={() => setdata("1 Day")}>1 Day</li>
                <li onClick={() => setdata("1 Week")}>1 Week</li>
              </ul>
            </button>
          </div>

          <button className="updatebtn" onClick={() => update()}>
            UPDATE INTERVAL
          </button>
          <button className="updatebtn" onClick={() => updatePrivateKey()}>
            UPDATE PRIVATE KEY
          </button>
        </div>
      </div>
    </div>
  );
}

export default Home;
