import React from "react";
import { IoClose, IoCloseSharp } from "react-icons/io5";
import { LOGO } from "../../../assets/logo";
import "./walletConnect.css";
import { useAccount, useConnect, useSignMessage, useDisconnect } from "wagmi";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { useNavigate  } from 'react-router-dom';

function WalletConnect({ open, setopen }) {
  const navigate = useNavigate();
  const { connectAsync } = useConnect();
  const { disconnectAsync } = useDisconnect();
  const { isConnected } = useAccount();
  const handleAuth = async (wal) => {
    if (isConnected) {
      await disconnectAsync();
    }

    console.log("Connect To Site Via Wallet");

    const userData = {};

    if (wal === "meta") {
      try {
        const { account, chain, error } = await connectAsync({
          connector: new MetaMaskConnector({})
        });

        userData.wallet_address = account;
        userData.chain = chain.id;
        userData.network = "metamask";
        localStorage.setItem("walletId", account);
        navigate("/home");
      } catch (error) {
        console.log("install metamask");
      }
    }

    console.log(userData);
  };
  return (
    <div className="walletmodals" style={!open ? { display: "none" } : null}>
      <div className="walletModal">
        <header>
          Connect a Wallet
          <IoClose onClick={() => setopen(!open)} />
        </header>

        <button onClick={() => handleAuth("meta")}>
          <img src={LOGO.METAMASK} alt="" />
          Metamask
        </button>
      </div>
    </div>
  );
}

export default WalletConnect;
