import React, { useState } from "react";
import "./login.css";
import WalletConnect from "../../components/modal/walletconnect/WalletConnect";
function LogIn() {
  const [open, setopen] = useState(false);
  return (
    <>
      <div className="login">
        <button onClick={() => setopen(!open)} className="connect">CONNECT</button>
      </div>
      <WalletConnect open={open} setopen={setopen}/>
    </>
  );
}

export default LogIn;
